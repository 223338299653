import React, { useState } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function AlertSettings(props) {
    console.log('load alert settings page')
    const [thresholds, setThres] = useState({
        'max_temperature_thres': '',
        'min_temperature_thres': '',
    });

    React.useEffect(() => {
        if (props.sensorId) {
            fetch(`${props.config.backend_url}/sensor_info/${props.sensorId}`, {
                headers: {
                    'Authorization': cookies.get('idToken')
                }
            })
                .then(res => {
                    if (!res.ok) {
                        window.location.href = props.config.login_url;
                    }
                    return res.json()
                })
                .then(data => {
                    setThres(data.body)
                })
        }
    }, [props.sensorId])

    function makeChange(event) {
        const { name, value } = event.target
        setThres(prev => {
            return {
                ...prev, [name]: value,
            }
        })
    }

    function handleSubmit(event) {
        event.preventDefault()
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies.get('idToken')
            },
            body: JSON.stringify({
                'sensor': props.sensorId,
                'max_temperature_thres': thresholds.max_temperature_thres,
                'min_temperature_thres': thresholds.min_temperature_thres,
            })
        };
        fetch(`${props.config.backend_url}/set_alert_thres`, requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = props.config.login_url;
                }
                console.log(res)
            })
            .then(data => {
                //window.location.href = props.config.main_url;
                window.location.reload(false)
                console.log('submit form')
            })
    }

    //console.log(thresholds, selectedSensorId)
    return (
        <div className='setting'>

            {/* max temp */}
            <div>
                <label htmlFor="maxTemperatureThres">Max Temperature:</label>
                <input
                    type="text"
                    id="max_temperature_thres"
                    name="max_temperature_thres"
                    value={thresholds.max_temperature_thres}
                    onChange={makeChange}
                />
            </div>

            {/* min temp */}
            <div>
                <label htmlFor="min_temperature_thres">Min Temperature:</label>
                <input
                    type="text"
                    id="min_temperature_thres"
                    name="min_temperature_thres"
                    value={thresholds.min_temperature_thres}
                    onChange={makeChange}
                />
            </div>

            {/* submit */}
            <button onClick={handleSubmit}>submit</button>
        </div>

    )
}

export default AlertSettings;