import React, { useState } from 'react';

import gp1 from './floorplans/L1_GP-1-1.jpg'
import mm1 from './floorplans/L1_MM-1-1.jpg'
import bo2 from './floorplans/L2_Bollore-1-1.jpg'
import mi2 from './floorplans/L2_mitsuisoko-1-1.jpg'
import dk2 from './floorplans/L4_DKSH-1-1.jpg'
import bo5 from './floorplans/L5_Bollore-1-1.jpg'

const options = {
    'L1-GP': gp1,
    'L1-MM': mm1,
    'L2-Bollore': bo2,
    'L2-Mitsui Soko': mi2,
    'L4-DKSH': dk2,
    'L5-Bollore': bo5
};

const labels = {
    'L1-GP': ['1'],
    'L1-MM': ['2','3','4','5','6'],
    'L2-Bollore': ['7','8'],
    'L2-Mitsui Soko': ['9'],
    'L4-DKSH': ['10', '11', '12', '13'],
    'L5-Bollore': ['14', '15']
}

const labels2 = {
    '1': 'L1-GP',
    '2': 'L1-MM',
    '3': 'L1-MM',
    '4': 'L1-MM',
    '5': 'L1-MM',
    '6': 'L1-MM',
    '7': 'L2-Bollore',
    '8': 'L2-Bollore',
    '9': 'L2-Mitsui Soko',
    '10': 'L4-DKSH',
    '11': 'L4-DKSH',
    '12': 'L4-DKSH',
    '13': 'L4-DKSH',
    '14': 'L5-Bollore',
    '15': 'L5-Bollore',
}

export function FloorPlan2(props) {
    const [selectedOption, setSelectedOption] = useState('');

    React.useEffect(() => {
        setSelectedOption(labels2[props.sensorId]);
    }, [props.sensorId])

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <h3>Floor Plan ({selectedOption})</h3>
            <div>
                {selectedOption && (
                    <img
                        src={options[selectedOption]}
                        alt={`Image ${selectedOption}`}
                        style={{ width: '80vw' }}
                    />
                )}
            </div>

            {selectedOption && labels[selectedOption].map((value, index) =>
                <span key={index}>{value}: {props.sensorList[value]}</span>
            )}
        </div>
    );
};

export function FloorPlan(props) {
    const [selectedOption, setSelectedOption] = useState('');

    function handleOptionChange(event) {
        setSelectedOption(event.target.value);
    }

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <div style={{ height: '10%', marginBottom: '5px' }}>
                <select value={selectedOption} onChange={handleOptionChange} >
                    <option value="">Select an option</option>
                    {Object.keys(options).map((key) => (
                        <option key={key} value={key}>{key}</option>
                    ))}
                </select>
            </div>

            <div>
                {selectedOption && (
                    <img
                        src={options[selectedOption]}
                        alt={`Image ${selectedOption}`}
                        style={{ width: '80vw' }}
                    />
                )}
            </div>

            {selectedOption && labels[selectedOption].map((value, index) =>
                <span key={index}>{value}: {props.sensorList[value]}</span>
            )}

        </div>
    );
};