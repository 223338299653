import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


function Download(props) {
    function handleDownloadPDF() {
        const content = props.contentRef.current;

        if (!content) {
            return;
        }
        // Capture the content as an image using html2canvas
        html2canvas(content).then((canvas) => {
            // Create a PDF using jsPDF
            const pdf = new jsPDF('portrait', 'pt', 'letter'); // 'portrait' or 'landscape'
            const imgData = canvas.toDataURL('image/jpeg', 1.0); // Convert the canvas to a data URL
            pdf.addImage(imgData, 'JPEG', 0, 0, 600, 200); // Add the image to the PDF
            pdf.save(`${props.filter.sensor}.pdf`); // Save the PDF with a specific filename
        });
    };

    if (props.filter.sensor) {
        return (
            <button className='download-button' onClick={handleDownloadPDF}>Download Graph</button>
        )
    }
    else {
        return (
            <button style={{ backgroundColor: 'grey' }} disabled>
                Download Graph
            </button>
        )
    }

}

export default Download;