import React from 'react';
import Download from './Download';
import ExportCSV from './ExportCSV';


function Topbar(props) {
    return (
        <div className='topbar'>
            {/*select sensor*/}
            <select
                id="sensor"
                name='sensor'
                value={props.value.sensor}
                onChange={props.trigger}
            >
                <option value='' disabled>- select sensor -</option>
                {props.sensorList ? (
                    Object.entries(props.sensorList).map(([key, value]) => (
                        <option value={key} key={key}>
                            {value}
                        </option>
                    ))
                ) : []}

            </select>

            {/*battery level*/}
            <span>&nbsp;</span>
            <div className='battery'>
                <span>{props.value.battery}%</span>
            </div>
            <span>&nbsp;&nbsp;</span>

            {/*select start time*/}
            <input
                type="datetime-local"
                id="timeFrom"
                name="timeFrom"
                //max={now.toISOString().slice(0, -8)}
                value={props.value.timeFrom}
                onChange={props.trigger}
            />
            <span>&nbsp;-&nbsp;</span>

            {/*select end time*/}
            <input
                type="datetime-local"
                id="timeTo"
                name="timeTo"
                min={props.value.timeFrom.slice(0, -3)}
                //max={now.toISOString().slice(0, -5)}
                value={props.value.timeTo}
                onChange={props.trigger}
            />
            <span>&nbsp;</span>
            <Download data={props.data} contentRef={props.contentRef} filter={props.value}/>
            <span>&nbsp;</span>
            <ExportCSV data={props.data} filter={props.value} />

        </div>
    )
}

export default Topbar;