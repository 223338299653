import React, { useRef } from 'react';
import { BrowserRouter, NavLink, Routes, Route } from 'react-router-dom'
import Cookies from 'universal-cookie';

import Topbar from './Topbar'
import Checkbox from './Checkbox'
import Chart1 from './Chart1'
import DisplayData from './DisplayData'

import logo from './images/logo.png'
import AlertSettings from './AlertSettings';
import AlertList from './AlertList'
import AlertEmail from './AlertEmail';
import { FloorPlan, FloorPlan2 } from './FloorPlan';
import Overview from './Overview';
import config from './config';


function App() {
    const contentRef = useRef(null);
    const now = new Date()
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset())
    const timeFrom = new Date(now)
    timeFrom.setHours(now.getHours() - 1)

    const cookies = new Cookies();

    const urlParams = new URLSearchParams(window.location.href);
    const idToken = urlParams.get('id_token');
    if (idToken) {
        cookies.set('idToken', idToken, { path: '/' });
    }


    const [isLoading, setIsLoading] = React.useState(true);
    const [sensorList, setSensorList] = React.useState({});
    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState(
        {
            'sensor': '',
            'timeFrom': timeFrom.toISOString().slice(0, -5),
            'timeTo': now.toISOString().slice(0, -5),
            'battery': '0',
            'plot': true,
            'mean': true,
            'min': true,
            'max': true,
        }
    )
    const [alerts, setAlerts] = React.useState([])

    // log out
    function handleLogOut() {
        window.location.href = config.logout_url
        cookies.set('idToken', 'random_things', { path: '/' });
        console.log(cookies.get('idToken'))
    }

    // refresh alert list
    function handleRefreshAlerts() {
        fetch(`${config.backend_url}/get-alerts/`, {
            headers: {
                'Authorization': cookies.get('idToken')
            }
        })
            .then(res => res.json())
            .then(data => {
                const result = data.body.map(item => {
                    return [
                        item.alert_id.S,
                        item.sensor_id.S,
                        new Date(parseInt(item.timestamp.N) * 1000).toISOString().slice(0, -5).replace("T", " "),
                        item.message.S
                    ];
                });

                result.sort((a, b) => b[2].localeCompare(a[2]))
                setAlerts(result)
            })
            .catch((error) => {
                console.log('error getting alerts', error)
            });
    }

    // check authorization
    React.useEffect(() => {
        fetch(`${config.auth_url}`, {
            method: 'GET',
            headers: { 'Authorization': cookies.get('idToken') }
        })
            .then((res) => {
                if (res.ok) {
                    setIsLoading(false)
                } else {
                    window.location.href = config.login_url;
                }
            })
    })


    // refresh alert list
    React.useEffect(() => {
        handleRefreshAlerts()
        // eslint-disable-next-line
    }, [])

    // threshold for each sensor 
    React.useEffect(() => {
        if (filter.sensor) {
            fetch(`${config.backend_url}/sensor_info/${filter.sensor}`, {
                headers: {
                    'Authorization': cookies.get('idToken')
                }
            })
                .then(res => {
                    if (!res.ok) {
                        window.location.href = config.login_url;
                    }
                    return res.json()
                })
                .then(data => setFilter(prev => {
                    console.log(data)
                    return { ...prev, 'battery': data.body.battery_level }
                }))
        }
        // eslint-disable-next-line
    }, [filter.sensor])

    // get temperature given time range
    React.useEffect(() => {
        if (filter.sensor) {
            const timeFrom = new Date(filter.timeFrom).toISOString().slice(0, -5)
            const timeTo = new Date(filter.timeTo).toISOString().slice(0, -5)
            fetch(`${config.backend_url}/temperature-data/${filter.sensor}?time_from=${timeFrom}&time_to=${timeTo}`, {
                headers: {
                    'Authorization': cookies.get('idToken')
                }
            })
                .then(res => {
                    if (!res.ok) {
                        window.location.href = config.login_url;
                    }
                    return res.json()
                })
                .then(data => {
                    setData(data.body)
                })
        }
        // eslint-disable-next-line
    }, [filter.sensor, filter.timeFrom, filter.timeTo])


    // get list of sensor
    React.useEffect(() => {
        console.log('fetching sensor list', config.backend_url)
        fetch(`${config.backend_url}/get-sensor-list`, {
            method: 'GET',
            headers: {
                'Authorization': cookies.get('idToken')
            }
        })
            .then((res) => {
                if (!res.ok) {
                    console.log(res.status)
                    window.location.href = config.login_url;
                }
                return res.json()
            })
            .then(data => {
                setSensorList(data.body)
            })
            .catch((error) => {
                console.log('error here')
            });
        // eslint-disable-next-line
    }, [])


    const displayData = DisplayData(data, filter)

    function makeChange(event) {
        const { name, value, type, checked } = event.target
        setFilter(prev => {
            return { ...prev, [name]: type === 'checkbox' ? checked : value }
        })
    }

    const main =
        <div className='main'>
            <Topbar trigger={makeChange} value={filter} sensorList={sensorList} data={data} setData={setData} contentRef={contentRef} />
            <div className='chart'>
                <div ref={contentRef}>
                    <Chart1 filter={filter} data={displayData} />
                </div>
                <Checkbox trigger={makeChange} value={filter} />
            </div>
            <AlertSettings sensorId={filter.sensor} config={config} />
            <FloorPlan2 sensorId={filter.sensor} sensorList={sensorList}/>
        </div>

    function CustomNavLink({ to, label }) {
        const [activeLink, setActiveLink] = React.useState(null);

        const handleNavLinkClick = () => {
            setActiveLink(to);
        };

        return (
            <li>
                <NavLink
                    to={to}
                    className={`nav-link ${activeLink === to ? 'select' : ''}`}
                    onClick={handleNavLinkClick}
                >
                    {label}
                </NavLink>
            </li>
        );
    }


    if (isLoading) {
        return <div></div>
    } else {
        return (
            <div className='page'>
                <BrowserRouter>
                    <div className="leftbar">
                        <img className='logo-img' src={logo} alt='logo' />
                        <nav className='left-menu'>
                            <ul>
                                <CustomNavLink to='/' label='Chart' />
                                <CustomNavLink to='/overview' label={`Overview`} />
                                <CustomNavLink to='/alert_emails' label={`Alert Emails`} />
                                <CustomNavLink to='/alerts' label={`Alerts [${alerts.length}]`} />
                                <CustomNavLink to='/floorplan' label={`Floor Plan`} />
                                <button className='logOut' onClick={handleLogOut}>Sign out</button>
                            </ul>
                        </nav>
                    </div>
                    <div className='rightArea'>
                        <Routes>
                            <Route path='/' element={main} />
                            <Route path='/overview' element={<Overview config={config} />} />
                            <Route path='/alert_emails' element={<AlertEmail config={config} />} />
                            <Route path='/floorplan' element={<FloorPlan sensorList={sensorList}/>} />
                            <Route path='/alerts' element={<AlertList alerts={alerts} setAlerts={setAlerts} config={config} handleRefresh={handleRefreshAlerts} />} />
                        </Routes>
                    </div>
                </BrowserRouter>
            </div>
        )
    }
}

export default App;
