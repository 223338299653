
function Checkbox(props){
    return(
        <div >
            <input 
                type="checkbox" 
                id="plot" 
                name="plot" 
                checked={props.value.plot}
                onChange={props.trigger}
            />
            <label htmlFor="plot">plot</label>

            <input 
                type="checkbox" 
                id="mean" 
                name="mean" 
                checked={props.value.mean}
                onChange={props.trigger}
            />
            <label htmlFor="mean">avg</label>

            <input 
                type="checkbox" 
                id="max" 
                name="max" 
                checked={props.value.max}
                onChange={props.trigger}
            />
            <label htmlFor="max">max</label>

            <input 
                type="checkbox" 
                id="min" 
                name="min" 
                checked={props.value.min}
                onChange={props.trigger}
            />
            <label htmlFor="min">min</label>

        </div>
    )
}

export default Checkbox;