import React, { useState } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function AlertEmail(props) {
    const [emails, setEmails] = useState(new Set())
    const [newEmail, setNewEmail] = useState('')

    React.useEffect(() => {
        getEmails();
    }, []);

    function getEmails() {
        fetch(`${props.config.backend_url}/get-alert-emails/`, {
            headers: {
                'Authorization': cookies.get('idToken')
            }
        })
            .then(res => {
                if (!res.ok) {
                    window.location.href = props.config.login_url;
                }
                return res.json()
            })
            .then(data => {
                setEmails(data.body)
            })
            .catch((error) => {
                console.log('error getting alert emails', error)
            });
    }

    function addNewEmail(event) {
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies.get('idToken')
            },
            body: JSON.stringify({
                'email': newEmail
            })
        };
        if (newEmail.trim() !== '') {
            fetch(`${props.config.backend_url}/add-alert-email`, requestOptions)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = props.config.login_url;
                    }
                    return res.json()
                })
                .then(data => {
                    if( data.statusCode != 200){
                        alert(data.body)
                    }
                    getEmails()
                })
        }
    }

    function deleteEmail(email) {
        fetch(`${props.config.backend_url}/delete-alert-email/${email}`, {
            method: 'DELETE',
            headers: {
                'Authorization': cookies.get('idToken')
            }
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Failed to delete email');
                }
                getEmails()
            })
            .catch(error => {
                console.error('Error deleting email:', error);
            });
    }

    return (
        <div>
            <h3>Alert Email List</h3>
            <form onSubmit={addNewEmail}>
                <input
                    type='email'
                    onChange={(e) => setNewEmail(e.target.value)}
                />
                <button type="submit">Add Email</button>
            </form>

            {[...emails].map((email, index) => (
                <li key={index} className="list-without-bullet">
                    <button onClick={() => deleteEmail(email)}>x</button>
                    {email}
                </li>
            ))}
        </div>
    )
}

export default AlertEmail