import React from "react"
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';

const cookies = new Cookies();
const itemsPerPage = 12

function AlertList(props) {
    const [checks, setCheck] = React.useState({})
    const [checkAll, setCheckAll] = React.useState(false);

    // pagination
    const items = props.alerts
    const [itemOffset, setItemOffset] = React.useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    // select/ unselect an alert
    function toggle(event) {
        const { id, checked } = event.target
        setCheck(prev => {
            return { ...prev, [id]: checked }
        })
    }

    // select/ unselect all alerts
    function toggleAll(event) {
        const checked = event.target.checked;
        setCheckAll(checked);

        const updatedSelectedCheckboxes = {};
        for (let i = 0; i < currentItems.length; i++) {
            updatedSelectedCheckboxes[currentItems[i][0]] = checked;
        }
        setCheck(updatedSelectedCheckboxes);
    }

    // delete selected alerts
    function triggerDelete() {
        const deleteSensorId = Object.keys(checks).filter(key => checks[key] === true)
        console.log(deleteSensorId)
        fetch(`${props.config.backend_url}/delete-alerts/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies.get('idToken')
            },
            body: JSON.stringify({ 'alert_ids': deleteSensorId }),
        })
            .then((response) => {
                if (!response.ok) {
                    window.location.href = props.config.login_url;
                }
                window.location.reload();
                return response.json()
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    // delete all alerts
    function triggerDeleteAll() {
        fetch(`${props.config.backend_url}/delete-all-alerts/`, {
            method: 'GET',
            headers: {
                'Authorization': cookies.get('idToken')
            },
        })
            .then((response) => {
                if (!response.ok) {
                    window.location.href = props.config.login_url;
                }
                window.location.reload();
                return response.json()
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <div className="main">
            <div className="alerts-button">
                <button onClick={triggerDelete}>Delete</button>
                <button onClick={triggerDeleteAll}>Delete All</button>
                <button onClick={props.handleRefresh}>Refresh</button>
                <br />
            </div>

            <div className="alerts">
                <table>
                    <thead>
                        <tr>
                            <th><input
                                type="checkbox"
                                checked={checkAll}
                                onChange={toggleAll}
                            ></input> </th>
                            <th>sensor</th>
                            <th>time</th>
                            <th>message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map(alert => {
                            const [alertId, sensorId, time, message] = alert
                            const timeLocal = new Date(time)
                            timeLocal.setMinutes(timeLocal.getMinutes() - timeLocal.getTimezoneOffset())
                            const timeDisplay = timeLocal.toLocaleString()

                            return (
                                <tr key={alertId}>
                                    <td><input
                                        type="checkbox"
                                        id={alertId}
                                        key={alertId}
                                        checked={checks[alertId] || false}
                                        onChange={toggle}
                                    ></input> </td>
                                    <td>{sensorId}</td>
                                    <td>{timeDisplay}</td>
                                    <td>{message}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <ReactPaginate className='ReactPaginate'
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </div>
    )
}

export default AlertList;