import { CSVLink } from 'react-csv';


/*
//input data format example
const data = [
  ['Name', 'Age', 'City'],
  ['John', '30', 'New York'],
];
*/
function ExportCSV(props) {
    if (props.filter.sensor) {
        return (
            <div>
                <button>
                    <CSVLink data={props.data} filename={props.filter.sensor} style={{ textDecoration: 'none', color: 'white' }}>
                        Download CSV
                    </CSVLink>
                </button>
            </div>
        )
    }
    else {
        return (
            <div>
                <button style={{ backgroundColor: 'grey' }} disabled>
                    Download CSV
                </button>
            </div>
        );
    }

}


export default ExportCSV;