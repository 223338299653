const config_product = {
    login_url: 'https://login.cwtclouds.com/oauth2/authorize?client_id=3r2m42gks66q46jfdi1qduhlq3&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fcwtclouds.com%2F',
    logout_url: 'https://login.cwtclouds.com/logout?client_id=3r2m42gks66q46jfdi1qduhlq3&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fcwtclouds.com%2F',
    main_url: 'https://cwtclouds.com',
    backend_url: 'https://nkdutr0nra.execute-api.ap-southeast-1.amazonaws.com/stage1',
    auth_url: 'https://nkdutr0nra.execute-api.ap-southeast-1.amazonaws.com/stage1/auth-prod'
  };

const config_test = {
    login_url: 'https://login.cwtclouds.com/oauth2/authorize?client_id=3r2m42gks66q46jfdi1qduhlq3&response_type=token&scope=email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A3000',
    logout_url: 'https://login.cwtclouds.com/logout?client_id=3r2m42gks66q46jfdi1qduhlq3&response_type=token&scope=email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A3000',
    main_url: 'http://localhost:3000',
    backend_url: 'https://nkdutr0nra.execute-api.ap-southeast-1.amazonaws.com/stage1',
    auth_url: 'https://nkdutr0nra.execute-api.ap-southeast-1.amazonaws.com/stage1/auth-prod'
}

const config = config_product
//const config = config_test
  
export default config;