import React, { useState } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function convert_to_local_time(timestamp) {
    const date = new Date(timestamp)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate
}

/* 
receive sensor name, temperature and time from backend
and return result in table format
*/
function Overview(props) {
    const [temperatures, setTemperatures] = useState({})

    // return the lastest temperatures for each sensor
    React.useEffect(() => {
        fetch(`${props.config.backend_url}/get-latest-temperatures/`, {
            headers: {
                'Authorization': cookies.get('idToken')
            }
        })
            .then(res => {
                if (!res.ok) {
                    window.location.href = props.config.login_url;
                }
                return res.json()
            })
            .then(data => {
                setTemperatures(data.body)
            })
            .catch((error) => {
                console.log('error getting alert emails', error)
            });
    }, []);


    return (
        <table>
            <thead>
                <tr>
                    <th>Sensor name</th>
                    <th>Last recorded temperature (°C)</th>
                    <th>Time</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(temperatures)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([key, [timestamp, temperature]]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{temperature}</td>
                            <td>{convert_to_local_time(timestamp)}</td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );
}

export default Overview