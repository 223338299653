import React from 'react';
import ReactApexChart from 'react-apexcharts';

const optionMap = {
    plot: {color:'#FF0000', width:3, dashArray:0},
    min : {color:'#0000FF', width:1, dashArray:3},
    max : {color:'#008000', width:1, dashArray:3},
    avg : {color:'#FF00FF', width:1, dashArray:3},
}

function ApexChart(props) {
    const series = props.data.series
    const options = {
        colors: props.data.series.map(x=>optionMap[x.name].color),
        chart: {
            animations: {
                enabled: false,
            },
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'xy',
                enabled: true,
                autoScaleYaxis: true,
            },
            toolbar: {
                autoSelected: 'zoom',
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        title: {
            text: 'Temperature @',
            align: 'left',
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0,
                opacityTo: 0,
                stops: [0, 90, 100],
            },
        },
        yaxis: {
            //max: props.data.max + 0.01,
            //min: props.data.min - 0.01,
            max: 30,
            min: 15,
            labels: {
                formatter: function (val) {
                    return (val).toFixed(2);
                },
            },
            title: {
                text: 'Temperature (°C)',
            },
        },
        xaxis: {
            type: 'datetime',
            //datetimeUTC: true,
        },
        stroke: {
            curve: 'straight', // Remove smoothing by setting curve to 'straight'
            width: 1,
        },
        tooltip: {
            shared: false,
            x:{
                show:true,
                format: 'dd-MMM HH:mm',
             },
            y: {
                formatter: function (val) {
                    return (val).toFixed(2);
                },
            },
        },
    }

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={options.chart.height}
        />
    );
}

export default ApexChart;
