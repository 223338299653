function DisplayData(data, filter){
    // convert to local time
    data = data.map(([timestamp, value]) => {
        const date = new Date(timestamp)
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
        return [formattedDate, value]
    })

    // Extract timestamps and values from the data
    const timestamps = data.map(([timestamp]) => {
        const date = new Date(timestamp)
        return date
    });
    const values = data.map(([, value]) => value);

    // Find the minimum and maximum timestamps
    const minTimestamp = new Date(Math.min(...timestamps));
    const maxTimestamp = new Date(Math.max(...timestamps));

    // Calculate the average, minimum, and maximum values
    const sum = values.reduce((acc, value) => acc + value, 0);
    const avgValue = sum / values.length;
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    const series = []
    if (filter.plot){
        series.push({name:'plot', data:data})
    } 
    if (filter.min){
        series.push({name:'min', data:[[minTimestamp, minValue],[maxTimestamp, minValue]]})
    }
    if (filter.max){
        series.push({name:'max', data:[[minTimestamp, maxValue],[maxTimestamp, maxValue]]})
    }
    if (filter.mean){
        series.push({name:'avg', data:[[minTimestamp, avgValue],[maxTimestamp, avgValue]]})
    }

    const outData = {'series':series, 'min':minValue, 'max':maxValue}

    return('outdata', outData)
}

export default DisplayData;